import React from "react";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import { Box, Typography } from "@material-ui/core";
import Link from "next/link";

export default function Custom404() {
  return (
    <>
      <Header />
      <Box
        height="75vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography component="h5">Página no encontrada</Typography>
        <Typography component="h4">
          <Link href="/">
            <span style={{ fontWeight: "bold", marginTop: "2rem" }}>
              Ir al inicio
            </span>
          </Link>
        </Typography>
      </Box>
      <Footer></Footer>
    </>
  );
}
